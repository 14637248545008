body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* Add Font-Family */
@font-face {
  font-family: "GilbertQualifiDemo";
  src: url("./Assets/Fonts/GilbertQualifiDemo.ttf");
  font-style: normal;
}

@font-face {
  font-family: "MontserratExtraLight";
  src: local("Montserrat"),
    url("./Assets/Fonts/Montserrat-ExtraLight.ttf") format("truetype");
}
@font-face {
  font-family: "MontserratLight";
  src: local("Montserrat"),
    url("./Assets/Fonts/Montserrat-Light.ttf") format("truetype");
}
@font-face {
  font-family: "MontserratRegular";
  src: local("Montserrat"),
    url("./Assets/Fonts/Montserrat-Regular.ttf") format("truetype");
}
@font-face {
  font-family: "MontserratMedium";
  src: local("Montserrat"),
    url("./Assets/Fonts/Montserrat-Medium.ttf") format("truetype");
}
@font-face {
  font-family: "MontserratBlack";
  src: local("Montserrat"),
    url("./Assets/Fonts/Montserrat-Black.ttf") format("truetype");
}
@font-face {
  font-family: "MontserratSemiBold";
  src: local("Montserrat"),
    url("./Assets/Fonts/Montserrat-SemiBold.ttf") format("truetype");
}
@font-face {
  font-family: "MontserratBold";
  src: local("Montserrat"),
    url("./Assets/Fonts/Montserrat-Bold.ttf") format("truetype");
}
@font-face {
  font-family: "MontserratExtraBold";
  src: local("Montserrat"),
    url("./Assets/Fonts/Montserrat-ExtraBold.ttf") format("truetype");
}
