* {
  margin: 0;
  padding: 0;
}

body {
  width: 100%;
  height: 100%;
  background-color: #ffffff;
}


/* SrollBar */

/* For Webkit-based browsers (Chrome, Safari) */
.custom-scrollbar-horizontal::-webkit-scrollbar {
  height: 1px;
}

.custom-scrollbar-horizontal::-webkit-scrollbar-thumb {
  background-color: #c6c6c6;
  border-radius: 10px;
  border: 5px solid #f5f5f5;
}

.custom-scrollbar-horizontal::-webkit-scrollbar-thumb:hover {
  background: #c6c6c6;
}

/* For Firefox */
.custom-scrollbar-horizontal {
  scrollbar-height: thin;
  scrollbar-color: #c6c6c6 #e0e0e0;
}

/* Custom scrollbar for Webkit-based browsers (Chrome, Safari) */
.custom-scrollbar-vertical::-webkit-scrollbar {
  width: 1px;
}

.custom-scrollbar-vertical::-webkit-scrollbar-thumb {
  background-color: #c6c6c68f;
  border-radius: 10px;
  border: 6px solid #ffffff;
}

.custom-scrollbar-vertical::-webkit-scrollbar-thumb:hover {
  background: #c6c6c68f;
}

/* Custom scrollbar for Firefox */
.custom-scrollbar-vertical {
  scrollbar-width: thin;
  scrollbar-color: #c6c6c6 #e0e0e0;
}
