.dots {
  display: flex;
  padding: 10px 0;
  justify-content: center;
}

.dot {
  border: none;
  width: 5px;
  height: 5px;
  background: #484848; /* Non-active dot color */
  border-radius: 50%;
  margin: 0 5px;
  padding: 0;
  cursor: pointer;
  transition: all 0.3s ease; /* Smooth transition for size change */
}

.dot:focus {
  outline: none;
}

.dot.active {
  background: #042b48; /* Active dot color */
  width: 10px;
  height: 10px;
}
