.react-calendar__month-view__weekdays__weekday abbr {
  /* Your custom styles for the day abbreviations */
  font-size: 12px;
  color: #7b8b97;
  font-family: "Montserrat", sans-serif;
  text-decoration: none;
  text-transform: capitalize;
}

.react-calendar__month-view__days__day--neighboringMonth,
.react-calendar__decade-view__years__year--neighboringDecade,
.react-calendar__century-view__decades__decade--neighboringCentury {
  color: #7b8b97; /* Change to your desired color */
  font-size: 14px;
  font-family: "Montserrat";
  font-weight: 400;
}
.react-calendar__tile--active {
  background: #042b48 !important;
  border-radius: 8px !important;
}

.customTile {
  /* Your other custom styles */
  position: relative;
}

.react-calendar .react-calendar__month-view__days__day--weekend {
  color: initial; /* Reset to initial color */
}

.react-calendar
  .react-calendar__month-view__days__day--weekend.react-calendar__tile--active {
  color: #fff; /* White color for active weekend days */
}

.customTile.even::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 1px; /* Adjust the height of the line */
  background-color: black; /* Adjust the color of the line */
}

.customWeekendDay {
  background-color: #000; /* Change this to BaseColor.white if defined */
  color: #fff;
  font-size: 14px;
}

.css-14wevf7-MuiButtonBase-root-MuiPickersDay-root.Mui-selected {
  background-color: #042b48 !important;
}

.css-aidsz1-MuiButtonBase-root-MuiMenuItem-root-MuiMultiSectionDigitalClockSection-item.Mui-selected {
  background-color: #042b48 !important;
}

.react-calendar__tile--now:enabled:hover,
.react-calendar__tile--now:enabled:focus {
  background-color: #fff !important;
}

.react-calendar__tile--hasActive {
  color: #042b48;
}

.react-calendar__tile:disabled {
  background-color: #fff !important;
  color: #000 !important;
}

.react-calendar__month-view__days__day--neighboringMonth:disabled {
  color: #7b8b97 !important;
}

.react-calendar__tile--now {
  background-color: #fff !important;
  color: #000 !important;
  border: 2px solid #042b48 !important;
}

.react-calendar__navigation button {
  min-width: 100px !important;
}

.react-calendar__navigation button.react-calendar__navigation__arrow:hover,
.react-calendar__navigation button.react-calendar__navigation__arrow:active {
  background-color: transparent !important;
}

.react-calendar__navigation button.react-calendar__navigation__arrow:hover,
.react-calendar__navigation button.react-calendar__navigation__arrow:focus {
  background-color: transparent !important;
  outline: none;
}
