// Query Sizes
$screen-sm: 640px;
$screen-md: 768px;
$screen-lg: 1024px;
$screen-xl: 1280px;

.common_container {
  width: 100%;

  @media screen and (min-width: $screen-sm) {
    padding: 0 5%;
  }

  @media screen and (min-width: $screen-xl) {
    padding: 0 10%;
  }
}